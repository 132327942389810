<template>
  <div style="width: 100%; height: 100vh;">
    <iframe :src="url" width="100%" height="100%" style="border: none"></iframe>
    <div class="btns">
      <van-button round block type="info" @click="close"
      >关闭</van-button
      >
    </div>
  </div>
</template>

<script>
  export default {
    name: "file-pdf",
    data () {
      return {
        url: ''
      }
    },
    methods: {
      close () {
        this.$router.go(-1)
      }
    },
    mounted() {
      this.pdfUrl = this.$route.query.pdfUrl
      this.url = 'https://www.hzuht.com/pdf/web/viewer.html?file=' + encodeURIComponent(this.pdfUrl)
    }
  }
</script>

<style scoped>

</style>
